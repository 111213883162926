import {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
  BottomSheetModal,
  BottomSheetView,
} from '@gorhom/bottom-sheet'
import { useCallback, useMemo, useRef } from 'react'
import { Platform } from 'react-native'
import { Dropdown } from 'react-native-element-dropdown'
import { KeyboardAvoidingView } from 'react-native-keyboard-controller'
import { Button } from 'react-native-paper'
import { createStyleSheet, useStyles } from 'react-native-unistyles'
import { KeyboardOffset } from '../../../constants'
import Species from '../../enums/species'
import { useAppStore } from '../../store/useAppStore'
import GridFilterSimpleSwitch from './GridFilterSimpleSwitch'

interface Props {
  open: boolean
  onClose: () => void
}

const GridFilterSheet = ({ open, onClose }: Props) => {
  const { styles } = useStyles(stylesheet)

  const bottomSheetModalRef = useRef<BottomSheetModal>(null)

  const filters = useAppStore.use.filters()
  const resetFilters = useAppStore.use.resetFilters()
  const setFilters = useAppStore.use.setFilters()

  const renderBackdrop = useCallback(
    (props: BottomSheetBackdropProps) => (
      <BottomSheetBackdrop
        {...props}
        disappearsOnIndex={-1}
        pressBehavior={'close'}
      />
    ),
    []
  )

  if (open) {
    bottomSheetModalRef.current?.present()
  } else {
    bottomSheetModalRef.current?.dismiss()
  }

  return (
    <BottomSheetModal
      backdropComponent={renderBackdrop}
      bottomInset={16}
      detached={true}
      enableDismissOnClose={true}
      enableDynamicSizing={true}
      onDismiss={onClose}
      ref={bottomSheetModalRef}
      style={{
        marginHorizontal: 16,
      }}
    >
      <BottomSheetView style={styles.root}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          keyboardVerticalOffset={KeyboardOffset}
          style={styles.keyboardContainer}
        >
          <Button
            disabled={!filters.isDirty}
            icon="restore"
            onPress={() => resetFilters()}
            style={styles.resetButton}
          >
            Reset Filters
          </Button>
          <Dropdown
            data={[
              { label: 'Dog', value: Species.Dog },
              { label: 'Cat', value: Species.Cat },
            ]}
            itemTextStyle={styles.text}
            labelField={'label'}
            onChange={(selected) => {
              setFilters({ ...filters, species: selected.value })
            }}
            placeholder="Species"
            placeholderStyle={styles.text}
            selectedTextStyle={styles.text}
            style={styles.filterContainer}
            value={filters.species}
            valueField={'value'}
          />
          <GridFilterSimpleSwitch
            filter="currentlyFostered"
            label="Currently Fostered"
            style={styles.filterContainer}
            textStyle={styles.text}
          />
          <GridFilterSimpleSwitch
            filter="readyForAdoption"
            label="Ready for Adoption"
            style={styles.filterContainer}
            textStyle={styles.text}
          />
          <GridFilterSimpleSwitch
            filter="includeDeceased"
            label="Include Deceased"
            style={styles.filterContainer}
            textStyle={styles.text}
          />
          <GridFilterSimpleSwitch
            filter="viewAll"
            label="View All"
            style={styles.filterContainer}
            textStyle={styles.text}
          />
        </KeyboardAvoidingView>
      </BottomSheetView>
    </BottomSheetModal>
  )
}

const stylesheet = createStyleSheet((theme) => {
  return {
    filterContainer: {
      alignItems: 'center',
      borderRadius: theme.tokens.containerBorderRadius,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: theme.tokens.spacing[4],
      paddingVertical: theme.tokens.spacing[4],
    },
    keyboardContainer: {
      justifyContent: 'center',
      paddingBottom: theme.tokens.spacing[4],
      paddingHorizontal: theme.tokens.spacing[4],
    },
    resetButton: {
      alignSelf: 'flex-end',
    },
    root: {
      paddingBottom: theme.tokens.spacing[4],
    },
    text: {
      fontSize: 18,
    },
  }
})

export default GridFilterSheet
