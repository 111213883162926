// All of the requested modules are loaded behind enumerable getters.
const map = Object.defineProperties({}, {
  "./(app)/_layout.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/_layout.tsx"); } },
  "./(app)/fosters/[id]/_layout.native.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/fosters/[id]/_layout.native.tsx"); } },
  "./(app)/fosters/[id]/_layout.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/fosters/[id]/_layout.tsx"); } },
  "./(app)/fosters/[id]/adoptions.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/fosters/[id]/adoptions.tsx"); } },
  "./(app)/fosters/[id]/documents.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/fosters/[id]/documents.tsx"); } },
  "./(app)/fosters/[id]/index.native.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/fosters/[id]/index.native.tsx"); } },
  "./(app)/fosters/[id]/index.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/fosters/[id]/index.tsx"); } },
  "./(app)/fosters/[id]/medical.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/fosters/[id]/medical.tsx"); } },
  "./(app)/fosters/_layout.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/fosters/_layout.tsx"); } },
  "./(app)/fosters/index.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/fosters/index.tsx"); } },
  "./(app)/fosters/search/index.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/fosters/search/index.tsx"); } },
  "./(app)/index.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/index.tsx"); } },
  "./(app)/organization/settings.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/organization/settings.tsx"); } },
  "./(app)/users/[id].tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/users/[id].tsx"); } },
  "./(app)/users/_layout.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/users/_layout.tsx"); } },
  "./(app)/users/index.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/(app)/users/index.tsx"); } },
  "./[...404].tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/[...404].tsx"); } },
  "./_layout.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/_layout.tsx"); } },
  "./auth/verify.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/auth/verify.tsx"); } },
  "./login.tsx": { enumerable: true, get() { return require("/vercel/path0/apps/expo/src/app/login.tsx"); } },
});

function metroContext(request) {
    return map[request];
}

// Return the keys that can be resolved.
metroContext.keys = function metroContextKeys() {
  return Object.keys(map);
};

// Return the module identifier for a user request.
metroContext.resolve = function metroContextResolve(request) {
  throw new Error('Unimplemented Metro module context functionality');
}

module.exports = metroContext;