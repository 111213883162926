import { StateCreator } from 'zustand'
import Species from '../enums/species'

const defaultFilters = {
  isDirty: false,
  currentlyFostered: false,
  includeDeceased: false,
  readyForAdoption: false,
  species: undefined as Species | undefined,
  viewAll: false,
}

type Filters = typeof defaultFilters

export interface FilterSlice {
  filters: Filters
  setFilters: (filters: Filters) => void
  resetFilters: () => void
}

export const createFilterSlice: StateCreator<FilterSlice> = (set) => ({
  filters: defaultFilters,
  setFilters: ({
    currentlyFostered,
    includeDeceased,
    readyForAdoption,
    species,
    viewAll,
  }: Filters) => {
    set({
      filters: {
        // isDirty should be true if any of the filters are different from the default
        isDirty: !(
          currentlyFostered === defaultFilters.currentlyFostered &&
          includeDeceased === defaultFilters.includeDeceased &&
          readyForAdoption === defaultFilters.readyForAdoption &&
          species === defaultFilters.species &&
          viewAll === defaultFilters.viewAll
        ),
        currentlyFostered,
        includeDeceased,
        readyForAdoption,
        species,
        viewAll,
      },
    })
  },
  resetFilters: () => {
    set({
      filters: {
        ...defaultFilters,
        isDirty: false,
      },
    })
  },
})
