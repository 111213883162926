import { Image, ImageStyle } from 'expo-image'
import { StyleProp } from 'react-native'
import Species from '../../enums/species'

interface Props {
  blurhash: string | null | undefined
  thumbUri: string | null | undefined
  species: Species
  style: StyleProp<ImageStyle>
  transition?: number
}

const FosterThumbnail = ({
  blurhash,
  thumbUri,
  species,
  style,
  transition,
}: Props) => {
  return (
    <Image
      accessibilityIgnoresInvertColors={true}
      cachePolicy={'disk'}
      contentFit="cover"
      placeholder={{ blurhash: blurhash || undefined }}
      source={
        blurhash
          ? thumbUri
          : species === 'Dog'
            ? require('../../../assets/dog.png')
            : require('../../../assets/cat.png')
      }
      style={style}
      testID="foster-image"
      transition={transition || 0}
    />
  )
}

export default FosterThumbnail
