import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Switch, Text, TouchableRipple } from 'react-native-paper'
import { FilterSlice } from '../../store/filterSlice'
import { useAppStore } from '../../store/useAppStore'

interface Props {
  filter: {
    [K in keyof FilterSlice['filters']]: FilterSlice['filters'][K] extends boolean
      ? K
      : never
  }[keyof FilterSlice['filters']]
  label: string
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}

const GridFilterSimpleSwitch = ({ filter, label, style, textStyle }: Props) => {
  const filters = useAppStore.use.filters()
  const setFilters = useAppStore.use.setFilters()

  return (
    <TouchableRipple
      accessibilityRole="button"
      borderless
      onPress={() => {
        filters[filter] = !filters[filter]
        setFilters(filters)
      }}
      style={style}
      testID={`${filter}-grid-filter-pressable`}
    >
      <>
        <Text style={textStyle}>{label}</Text>
        <Switch
          onValueChange={() => {
            filters[filter] = !filters[filter]
            setFilters(filters)
          }}
          role="checkbox"
          testID={`${filter}-grid-filter-switch`}
          value={filters[filter]}
        />
      </>
    </TouchableRipple>
  )
}

export default GridFilterSimpleSwitch
